
              @import "@/assets/css/variables.scss";
            























































































































































































// @import '@/views/home/common.scss';
// @import '@/assets/css/components/dateRangePicker.scss';
// .calendar {
//   display: flex;
// }
.special_date_picker {
  display: flex;

  .el-date-editor {
    width: 50%;

    // padding: 0 8px;
    /deep/ .el-input__inner {
      padding: 8px !important;
    }
  }

  .separator_span {
    padding: 0 8px;
  }
}
